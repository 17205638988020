import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import EntitlementTeaser from '@/entitlements/components/EntitlementTeaser';

const propTypes = {
  entitlement: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['default', 'datatable']),
  isShow: PropTypes.bool,
  isClosable: PropTypes.bool,
  isRefreshPageAfterPurchase: PropTypes.bool,
  onClose: PropTypes.func,
};
const defaultProps = {
  variant: 'default',
  isShow: false,
  isClosable: false,
  isRefreshPageAfterPurchase: undefined,
  onClose: () => {},
};

function InlineOverlayEntitlementTeaser({
  entitlement,
  variant,
  isShow,
  isClosable,
  isRefreshPageAfterPurchase,
  onClose,
}) {
  const contentContainer = useRef(null);

  // Functions
  function resizeContainer() {
    const container = contentContainer.current.closest('[data-entitlement-teaser-container]');
    const PADDING = 30;

    container.style.minHeight = `${contentContainer.current.offsetHeight + PADDING * 2}px`;
  }

  // Effects
  useEffect(() => {
    const container = contentContainer.current.closest('[data-entitlement-teaser-container]');

    if (container && contentContainer.current) {
      const containerObserver = new ResizeObserver(() => {
        resizeContainer();
      });
      containerObserver.observe(container);

      const contentContainerObserver = new ResizeObserver(() => {
        resizeContainer();
      });
      contentContainerObserver.observe(contentContainer.current);

      return () => {
        containerObserver.unobserve(container);
        contentContainerObserver.unobserve(contentContainer.current);
      };
    }

    return () => {};
  }, [contentContainer.current]);

  // Render
  if (!isShow) {
    return null;
  }

  return (
    <div
      className={`inline-overlay-entitlement-teaser inline-overlay-entitlement-teaser--${variant}`}
    >
      <div className="inline-overlay-entitlement-teaser__container" ref={contentContainer}>
        <EntitlementTeaser
          entitlement={entitlement}
          variant="inline-overlay"
          isRefreshPageAfterPurchase={isRefreshPageAfterPurchase}
        />

        {isClosable && (
          <button
            type="button"
            className="inline-overlay-entitlement-teaser__close"
            onClick={onClose}
          >
            <em className="icon icon-cancel" />
          </button>
        )}
      </div>
    </div>
  );
}

InlineOverlayEntitlementTeaser.propTypes = propTypes;
InlineOverlayEntitlementTeaser.defaultProps = defaultProps;

export default InlineOverlayEntitlementTeaser;
